import { ReactNode } from 'react';

export interface TableHeaderContentRendererProps {
  children: ReactNode;
}

export const TableHeaderContentRenderer = ({
  children
}: TableHeaderContentRendererProps): JSX.Element => (
  <div className="pr-4">{children}</div>
);
