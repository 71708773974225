import { Alert } from '@/components/basic';

export default function DuplicateEmailMessage(): JSX.Element {
  return (
    <Alert
      variant="error"
      canDismiss={false}
      alertTitle=""
      alertMessage=""
      className="mb-4 px-4 py-2"
    >
      Email is a duplicate. Log in{' '}
      <a
        className="cursor-pointer font-bold text-red-800 underline"
        onClick={() =>
          (window.location.href = `/auth/login?returnUrl=${encodeURIComponent(`${window.location.origin}/dashboard`)}`)
        }
      >
        here.
      </a>
      <br />
      If you are trying to merge accounts, please reach out to{' '}
      <a
        href="mailto:support@gettactic.com"
        className="cursor-pointer font-bold text-red-800 underline"
      >
        support@gettactic.com
      </a>
    </Alert>
  );
}
