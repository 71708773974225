import { IResource } from '@gettactic/api';
import { EntityType } from '@/lib/types/EntityType';
import { Input, Label } from '@/components/basic';

type ResourceRestrictionProps = {
  entity: EntityType;
  register: any;
  control: any;
  setValue: any;
  defaultValues: {
    is_approvable: boolean;
    is_restricted: boolean;
  };
  texts: any;
};

export function checkInitialIsRestricted(resource: IResource | undefined) {
  return !!resource && !resource.is_open;
}

export default function ResourceRestrictionControls({
  entity,
  register,
  defaultValues,
  texts
}: ResourceRestrictionProps): JSX.Element {
  return (
    <>
      <div className="flex">
        <div className="mt-4 flex shadow-none">
          <Input
            type="checkbox"
            {...register('is_approvable')}
            id={`${entity}-is_approvable`}
            aria-describedby={`description-${entity}-is_approvable`}
            className="shadow-none"
            defaultChecked={defaultValues.is_approvable}
          />

          <div className="ml-4">
            <Label
              htmlFor={`${entity}-is_approvable`}
              className="text-base font-bold text-gray-800"
            >
              Require approval
            </Label>
            <p
              className="text-base text-gray-800"
              id={`description-${entity}-is_approvable`}
            >
              {entity === 'zone' ? (
                <>
                  If you check this box, children resources will need a manager
                  to approve a reservation
                </>
              ) : (
                <>
                  If you check this box, users will need a manager to approve a
                  reservation before it&apos;s accepted by the system.
                </>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="mt-4 flex shadow-none">
          <Input
            type="checkbox"
            id={`${entity}-is_restricted`}
            aria-describedby={`description-${entity}-is_restricted`}
            {...register('is_restricted')}
            className="shadow-none"
            defaultChecked={defaultValues.is_restricted}
          />
          <div className="ml-4">
            <Label
              htmlFor={`${entity}-is_restricted`}
              className="text-base font-bold text-gray-800"
            >
              Restrict to specific teams &amp; users
            </Label>
            <p
              className="text-base text-gray-800"
              id={`description-${entity}-is_restricted`}
            >
              {entity === 'zone' ? (
                <>
                  If you check this box, only specific teams &amp; users can
                  reserve the children resources of this Zone
                </>
              ) : (
                <>
                  If you check this box, only specific teams &amp; users can
                  reserve the {texts[entity].individual}.
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
