import { DependencyList, EffectCallback, useEffect } from 'react';

/**
 * Helper hook to create a custom debounce
 * delay for a specific action.
 */
export const useDebouncedEffect = (
  effect: EffectCallback,
  deps?: DependencyList | undefined,
  delay = 0
) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);
    return () => clearTimeout(handler);
  }, [...(deps || []), delay]);
};
