import { Link, RichTextEditor } from '@mantine/tiptap';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

type Props = {
  value: string;
  onChange: (s: string | null) => void;
  id?: string;
};
export function TextEditor({ value, onChange, id }: Props) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      TextAlign.configure({ types: ['heading', 'paragraph'] })
    ],
    content: value,
    onUpdate({ editor }) {
      let content: string | null = editor.getHTML();
      const json = editor.getJSON().content;

      // If the editor is empty, we need to null out the content, otherwise empty <p></p> tags will be rendered
      if (
        Array.isArray(json) &&
        json.length === 1 &&
        !json[0].hasOwnProperty('content')
      ) {
        content = null;
      }
      onChange(content);
    }
  });

  return (
    <RichTextEditor editor={editor} styles={{ toolbar: { gap: '4px' } }}>
      <RichTextEditor.Toolbar>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Code />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content id={id} />
    </RichTextEditor>
  );
}

export default TextEditor;
