import React from 'react';
import { classNames } from '@/lib/classNames';

export const ResponsiveInput = React.forwardRef(
  (
    props: React.HTMLProps<HTMLInputElement> & {
      getRefCallback?: (ref: React.Ref<HTMLInputElement>) => void;
    },
    ref: React.Ref<HTMLInputElement>
  ) => {
    const { className, getRefCallback, ...rest } = props;
    getRefCallback && getRefCallback(ref);
    return (
      <input
        className={classNames(
          className,
          'text-trueGray-800 block w-full rounded-md border border-slate-200 px-4 py-2 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:mt-1 sm:border-gray-300 sm:px-3 sm:text-black',
          props.readOnly ? 'bg-gray-100' : ''
        )}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default ResponsiveInput;
