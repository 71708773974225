import { IResource } from '@gettactic/api';
import React from 'react';
import { classNames } from '@/lib/classNames';
import { checkInitialIsRestricted } from '@/components/partial/Cards/Resources/ResourceRestrictionControls';

type Props = {
  resource: IResource;
  extraClassesLabel?: string;
  restrictedTagsOnly?: boolean;
};

export default function ResourceRestrictionInfo({
  resource,
  extraClassesLabel = 'text-sm',
  restrictedTagsOnly = false
}: Props) {
  return (
    <>
      <div>
        {!restrictedTagsOnly && <span> Restricted: </span>}
        {!checkInitialIsRestricted(resource) ? (
          <span
            className={classNames(
              'inline-flex items-center rounded-full bg-red-100 px-2 py-0.5 font-medium text-red-800',
              extraClassesLabel
            )}
          >
            No
          </span>
        ) : null}
        {!resource.is_open &&
        resource.teams &&
        Object.keys(resource.teams).length > 0 ? (
          <span
            className={classNames(
              'ml-2 inline-flex items-center rounded-full bg-green-100 px-2 py-0.5 font-medium text-green-800',
              extraClassesLabel
            )}
          >
            Teams
          </span>
        ) : !resource.is_open &&
          resource.users &&
          Object.keys(resource.users).length > 0 ? (
          <span
            className={classNames(
              'ml-2 inline-flex items-center rounded-full bg-blue-100 px-2 py-0.5 font-medium text-secondary',
              extraClassesLabel
            )}
          >
            Users
          </span>
        ) : !resource.is_open ? (
          <span
            className={classNames(
              'ml-2 inline-flex items-center rounded-full bg-blue-100 px-2 py-0.5 font-medium text-secondary',
              extraClassesLabel
            )}
          >
            Yes
          </span>
        ) : null}
      </div>
      {!restrictedTagsOnly && (
        <div>
          <span>Requires approval: </span>
          {resource.is_approvable ? (
            <span
              className={classNames(
                'inline-flex items-center rounded-full bg-green-100 px-2 py-0.5 font-medium text-green-800',
                extraClassesLabel
              )}
            >
              Yes
            </span>
          ) : (
            <span
              className={classNames(
                'inline-flex items-center rounded-full bg-orange-100 px-2 py-0.5 font-medium text-orange-800',
                extraClassesLabel
              )}
            >
              No
            </span>
          )}
        </div>
      )}
    </>
  );
}
