import { Transition } from '@mantine/core';
import React, { useState } from 'react';
import { classNames } from '@/lib/classNames';

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Optional alert title
   */
  alertTitle?: string | React.ReactNode;
  /**
   * Optional alert title
   */
  alertMessage?: string;
  /**
   * Optional alert title
   */
  alertArray?: Array<string>;
  /**
   * Alert type
   */
  variant?: keyof typeof AlertEnum;
  /**
   * Optional ability to dismiss alert
   */
  canDismiss: boolean;
}

enum AlertEnum {
  error,
  warning,
  success,
  info,
  tacticAlert
}

enum TypeIcons {
  warning = 'M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z',
  error = 'M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z',
  info = 'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z',
  tacticAlert = 'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z',
  success = 'M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4',
  undefined = 'M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
}

function getTypeIcons(variant: string): TypeIcons {
  if (variant === 'success') {
    return TypeIcons.success;
  } else if (variant === 'info') {
    return TypeIcons.info;
  } else if (variant === 'warning') {
    return TypeIcons.warning;
  } else if (variant === 'tacticAlert') {
    return TypeIcons.tacticAlert;
  } else if (variant === 'error') {
    return TypeIcons.error;
  } else {
    return TypeIcons.error;
  }
}

const styles = Object.freeze({
  base: 'p-4 rounded-md',
  variants: {
    background: {
      error: 'bg-red-100',
      warning: 'bg-yellow-100',
      success: 'bg-green-100',
      info: 'bg-blue-100',
      tacticAlert: 'bg-primary-lighter'
    },
    icon: {
      error: 'text-red-400',
      warning: 'text-yellow-500',
      success: 'text-green-400',
      info: 'text-blue-400',
      tacticAlert: 'text-primary'
    },
    header: {
      error: 'text-red-800',
      warning: 'text-yellow-900',
      success: 'text-green-800',
      info: 'text-secondary',
      tacticAlert: 'text-primary-darker'
    },
    message: {
      error: 'text-red-700',
      warning: 'text-yellow-800',
      success: 'text-green-700',
      info: 'text-blue-700',
      tacticAlert: 'text-primary'
    },
    dismiss: {
      error:
        'bg-red-50 hover:bg-red-100 text-red-500 focus:ring-red-600 focus:ring-offset-red-50',
      warning:
        'bg-yellow-50 hover:bg-yellow-100 text-yellow-500 focus:ring-yellow-600 focus:ring-offset-yellow-50',
      success:
        'bg-green-50 hover:bg-green-100 text-green-500 focus:ring-green-600 focus:ring-offset-green-50',
      info: 'bg-blue-50 hover:bg-blue-100 text-blue-500 focus:ring-blue-600 focus:ring-offset-blue-50',
      tacticAlert:
        'bg-primary hover:bg-primary-hover text-primary focus-visible:ring-primary focus-visible:ring-offset-primary'
    }
  }
});

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    const {
      alertTitle = 'An error has occurred:',
      alertMessage = 'Please contact our support team.',
      alertArray,
      variant = 'error',
      canDismiss = false,
      children,
      className = '',
      ...other
    } = props;

    const transition = {
      in: { opacity: 1, transform: 'scaleY(1)' },
      out: { opacity: 0, transform: 'scaleY(0)' },
      common: { transformOrigin: 'top' },
      transitionProperty: 'transform, opacity'
    };

    const alertIcon = getTypeIcons(variant);
    const [open, setOpen] = useState(true);

    return (
      <Transition
        mounted={open}
        transition={transition}
        duration={100}
        timingFunction="ease-out"
      >
        {(transitionStyles) => (
          <div
            className={classNames(
              styles.base,
              styles.variants.background[variant],
              className
            )}
            style={transitionStyles}
            role="alert"
            ref={ref}
            {...other}
          >
            <div className="flex">
              <div className="shrink-0">
                <svg
                  className={classNames(
                    'h-5 w-5',
                    styles.variants.icon[variant]
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path fillRule="evenodd" d={alertIcon} clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <h3
                  className={classNames(
                    'text-sm font-medium',
                    styles.variants.header[variant]
                  )}
                >
                  {alertTitle}
                </h3>
                {alertMessage && (
                  <div
                    className={classNames(
                      'mt-2 text-sm',
                      styles.variants.message[variant]
                    )}
                  >
                    {alertMessage}
                  </div>
                )}
                {alertArray && (
                  <div
                    className={classNames(
                      'mt-2 text-sm',
                      styles.variants.message[variant]
                    )}
                  >
                    <ul className="list-disc pl-5">
                      {alertArray.map((alert) => (
                        <li key={alert}>{alert}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {children ? (
                  <div
                    className={classNames(
                      'mt-2 text-sm',
                      styles.variants.message[variant]
                    )}
                  >
                    {children}
                  </div>
                ) : null}
              </div>
              {canDismiss && (
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      aria-label="close"
                      onClick={() => setOpen(!open)}
                      className={classNames(
                        'inline-flex rounded-md p-1.5 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2',
                        styles.variants.dismiss[variant]
                      )}
                    >
                      <span className="sr-only">Dismiss</span>
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Transition>
    );
  }
);
export default Alert;
