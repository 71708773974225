import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { LoadingSpinner } from '../../loadings/LoadingSpinner';
import { ButtonProps } from './Button.types';
import { buttonVariants } from './Button.variants';

export const Button = React.forwardRef<
  ReactNode | HTMLElement | string,
  ButtonProps
>(function Button(props, ref) {
  const {
    tag = 'button',
    // Fix https://github.com/estevanmaito/windmill-react-ui/issues/7
    type = tag === 'button' ? 'button' : undefined,
    disabled = false,
    loading = false,
    size = 'base',
    iconSize,
    layout = 'rounded',
    icon,
    iconLeft,
    iconRight,
    severity,
    variant = 'primary',
    font = 'boldPrimary',
    justifyCenter,
    mobileFull,
    className,
    children,
    ...other
  } = props;

  function hasIcon() {
    return !!icon || !!iconLeft || !!iconRight;
  }

  const IconLeft = iconLeft || icon;
  const IconRight = iconRight;

  /**
   * Only used in DropdownItem.
   * Not meant for general use.
   */
  const dropdownItemStyle = buttonVariants.dropdownItem.base;

  const severityStyle = severity ? buttonVariants.severity[severity] : null;

  const buttonStyles =
    layout === '__dropdownItem'
      ? clsx(
          dropdownItemStyle,
          className,
          'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary'
        )
      : clsx(
          buttonVariants.base,
          // Focus outline for keyboard accessibility
          'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary',
          // has icon but no children
          hasIcon() && !children && buttonVariants.size.icon[size],
          // has icon and children
          hasIcon() && children && buttonVariants.size[size],
          hasIcon() &&
            children &&
            buttonVariants.size[size] &&
            ' flex items-center',
          // does not have icon
          !hasIcon() && buttonVariants.size[size],
          buttonVariants[layout],
          severityStyle?.base ||
            (disabled || loading ? {} : buttonVariants.variants[variant].base),
          buttonVariants.font[font],
          disabled || loading
            ? buttonVariants.variants[variant].disabled
            : severityStyle?.active || buttonVariants.variants[variant].active,
          mobileFull ? 'w-full md:w-auto' : '',
          justifyCenter ? buttonVariants.justifyCenter : '',
          className
        );

  const iconLeftStyles = clsx(
    buttonVariants.icon[iconSize || size],
    children ? buttonVariants.icon.left : ''
  );
  const iconRightStyles = clsx(
    buttonVariants.icon[iconSize || size],
    children ? buttonVariants.icon.right : ''
  );

  return React.createElement(
    tag,
    {
      className: buttonStyles,
      ref,
      disabled,
      type,
      ...other
    },
    IconLeft
      ? React.createElement(IconLeft, {
          className: iconLeftStyles,
          'aria-hidden': true
        })
      : null,
    loading ? (
      <div className={'relative'}>
        <div className={'opacity-0'}>{children}</div>
        <div className={'absolute inset-0'}>
          <LoadingSpinner />
        </div>
      </div>
    ) : (
      children
    ),
    IconRight
      ? React.createElement(IconRight, {
          className: iconRightStyles,
          'aria-hidden': true
        })
      : null
  );
});

export default Button;
