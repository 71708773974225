import React from 'react';
import { classNames } from '@/lib/classNames';

export type PresizedHeadingProps = React.PropsWithChildren<{
  className?: string;
  color?: 'orange' | 'blue' | 'white' | 'blue-alt' | 'primary';
}>;
export type HeadingProps = PresizedHeadingProps & {
  size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

const styles = {
  color: {
    orange: 'text-primary',
    blue: 'text-secondary-bg',
    white: 'text-white',
    'blue-alt': 'text-gray-700',
    primary: 'text-primary'
  },
  h1: 'text-4xl lg:text-6xl leading-tight font-bold',
  h2: 'text-3xl sm:text-4xl leading-snug sm:leading-tight font-bold',
  h3: 'text-2xl sm:text-3xl leading-snug font-bold',
  h4: 'text-xl sm:text-2xl leading-relaxed sm:leading-snug font-medium sm:font-bold',
  h5: 'text-xl sm:text-2xl leading-relaxed sm:leading-snug font-medium',
  h6: 'text-xl sm:text-lg leading-relaxed sm:leading-snug font-medium sm:font-bold'
};
export const Heading = ({
  size,
  children,
  className,
  color = 'blue'
}: HeadingProps): JSX.Element =>
  React.createElement(
    size,
    {
      className: classNames(styles.color[color], styles[size], className)
    },
    children
  );

export const h1 = (props: PresizedHeadingProps): JSX.Element => (
  <Heading {...props} size="h1" />
);
export const h2 = (props: PresizedHeadingProps): JSX.Element => (
  <Heading {...props} size="h2" />
);
export const h3 = (props: PresizedHeadingProps): JSX.Element => (
  <Heading {...props} size="h3" />
);
export const h4 = (props: PresizedHeadingProps): JSX.Element => (
  <Heading {...props} size="h4" />
);
export const h5 = (props: PresizedHeadingProps): JSX.Element => (
  <Heading {...props} size="h5" />
);
export const h6 = (props: PresizedHeadingProps): JSX.Element => (
  <Heading {...props} size="h6" />
);
