import colors from 'tailwindcss/colors';

export const getLighterColor = (color: string, transparency = 1) => {
  const hex = color.replace('#', '');
  const r = Number.parseInt(hex.substring(0, 2), 16);
  const g = Number.parseInt(hex.substring(2, 4), 16);
  const b = Number.parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${transparency.toString()})`;
};

export const getDarkerColor = (color: string, factor = 0.1) => {
  const hex = color.replace('#', '');
  const r = Number.parseInt(hex.substring(0, 2), 16);
  const g = Number.parseInt(hex.substring(2, 4), 16);
  const b = Number.parseInt(hex.substring(4, 6), 16);

  const new_r = Math.floor(r * (1 - factor));
  const new_g = Math.floor(g * (1 - factor));
  const new_b = Math.floor(b * (1 - factor));

  return `rgba(${new_r}, ${new_g}, ${new_b}, 1)`;
};

const PRIMARY_COLOR = '#EA580C';
const PRIMARY_CONTRAST_COLOR = '#FFFFFF';

const SECONDARY_COLOR = '#082F49';
const SECONDARY_CONTRAST_COLOR = '#FFFFFF';

const TERTIARY_COLOR = '#020617';
const TERTIARY_CONTRAST_COLOR = '#FFFFFF';

const BRAND_ACCENT = colors.teal[600];
const HIGHLIGHT = '186 230 253'; //rgb format

// -----------------------------------
// Static Color: Applicable for all themes
// -----------------------------------
const PAST_EVENT = '#CBD5E0BF';
const PAST_EVENT_CONTRAST = colors.slate[600];

const SUCCESS_COLOR = '#BBF7D0';
const SUCCESS_CONTRAST_COLOR = '#14532D';

const ERROR_COLOR = '#FEE2E2';
const ERROR_CONTRAST_COLOR = '#7F1D1D';

const DISABLED = colors.gray[400];

// -----------------------------------

const base = {
  brand: BRAND_ACCENT,
  highlight: HIGHLIGHT, // sky blue
  disabled: DISABLED, // Disabled color
  primary: {
    bg: PRIMARY_COLOR,
    DEFAULT: PRIMARY_COLOR,
    text: PRIMARY_CONTRAST_COLOR,
    hover: getLighterColor(PRIMARY_COLOR, 0.8),
    lighter: getLighterColor(PRIMARY_COLOR, 0.05),
    darker: getDarkerColor(PRIMARY_COLOR, 0.1)
  },
  secondary: {
    DEFAULT: SECONDARY_COLOR,
    bg: SECONDARY_COLOR,
    text: SECONDARY_CONTRAST_COLOR,
    hover: getLighterColor(SECONDARY_COLOR, 0.8)
  },
  tertiary: {
    DEFAULT: TERTIARY_COLOR,
    text: TERTIARY_CONTRAST_COLOR,
    bg: TERTIARY_COLOR,
    hover: getLighterColor(TERTIARY_COLOR, 0.8)
  },
  event: {
    past: {
      DEFAULT: PAST_EVENT,
      text: PAST_EVENT_CONTRAST,
      bg: PAST_EVENT
    }
  },
  success: {
    DEFAULT: SUCCESS_COLOR,
    text: SUCCESS_CONTRAST_COLOR,
    bg: SUCCESS_COLOR
  },
  error: {
    DEFAULT: ERROR_COLOR,
    text: ERROR_CONTRAST_COLOR,
    bg: ERROR_COLOR
  },
  hyperlink: '#4299e1',
  input: '#E5E5E5',
  background: '#FFFFFF',
  overlay: '#6B7280CC',
  map: {
    availableColor: 'rgba(156, 215, 168, 1)',
    notAvailableColor: 'rgba(169, 169, 169, 1)',
    spotNotBookeableUnavailableStroke: 'rgba(153, 95, 95, 0.46)',
    spotNotBookeableUnavailableFill: 'rgba(232, 236, 239, 1)',
    spotNotBookeableStroke: 'rgba(255, 255, 255, 1)',
    spotNotBookeableFill: '#223c5b',
    spotRequiresApprovalStroke: 'rgba(255, 255, 255, 1)',
    spotRequiresApprovalFill: 'rgba(252, 211, 77, 1)',
    spotBookeableWithSchedulesStroke: 'rgba(255, 255, 255, 1)',
    spotFullyAvailableStroke: 'rgba(255, 255, 255, 1)',
    spotNotAssignedFill: 'rgba(232, 236, 239, 1)'
  }
};

export default base;
