import React from 'react';

import { classNames } from '@/lib/classNames';

type Props = React.HTMLAttributes<HTMLDivElement>;

const styles = Object.freeze({
  base: 'p-4'
});

export const CardBody = React.forwardRef<HTMLDivElement, Props>(
  function CardBody(props, ref) {
    const { className, children, ...other } = props;

    return (
      <div className={classNames(styles.base, className)} ref={ref} {...other}>
        {children}
      </div>
    );
  }
);

export default CardBody;
