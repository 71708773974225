import React from 'react';
import { classNames } from '@/lib/classNames';

export interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  /**
   * Is label for a checkbox? Applies checkbox styling.
   */
  check?: boolean;
  /**
   * Is label for a radio button? Applies radio button styling.
   */
  radio?: boolean;
  /**
   * Explicity "for" attribute to tie a label to input
   */
  htmlFor?: string;
  /**
   * Is label disabled? Applies disabled styling.
   */
  disabled?: boolean;
  /**
   * Additional class names. Applied to <label>.
   */
  className?: string;
  /**
   * Optional children. Typically text for text input.
   */
  children?: React.ReactNode;
}

const styles = Object.freeze({
  base: 'block text-secondary',
  checkbox: 'inline-flex items-center',
  radio: 'inline-flex items-center',
  disabled: 'opacity-50 cursor-not-allowed'
});

export function Label({
  children,
  check,
  radio,
  disabled,
  htmlFor,
  className
}: LabelProps): JSX.Element {
  const cls = classNames(
    styles.base,
    check ? styles.checkbox : '',
    radio ? styles.radio : '',
    disabled ? styles.disabled : '',
    className ? className : ''
  );

  return (
    <label className={cls} htmlFor={htmlFor}>
      {children}
    </label>
  );
}

export default Label;
