import { InformationCircleIcon } from '@heroicons/react/outline';
import { Popover } from '@mantine/core';
import { FC, ReactNode } from 'react';
import { memo } from 'react';
import { classNames } from '@/lib/classNames';

interface Props {
  width?: number;
  size?: 'sm' | 'md' | 'lg';
  position?: 'top' | 'right' | 'bottom' | 'left';
  testId?: string;
  children: ReactNode;
}

export const Tooltip: FC<Props> = memo(
  ({ width = 300, size = 'md', position = 'bottom', testId, children }) => {
    return (
      <Popover width={width} position={position} withArrow shadow="md">
        <Popover.Target>
          <InformationCircleIcon
            className={classNames('ml-2 inline cursor-pointer text-gray-400', {
              'h-4 w-4': size === 'sm',
              'h-5 w-5': size === 'md',
              'h-7 w-7': size === 'lg'
            })}
            data-testid={testId}
          />
        </Popover.Target>
        <Popover.Dropdown>{children}</Popover.Dropdown>
      </Popover>
    );
  }
);
