import { classNames } from '@/lib/classNames';

export interface BooleanTableCellContentRendererProps {
  value: boolean | undefined;
}

export const BooleanTableCellContentRenderer = ({
  value
}: BooleanTableCellContentRendererProps) => (
  <span
    className={classNames(
      value ? 'bg-green-100 text-green-800' : 'bg-orange-100 text-orange-800',
      'inline-flex items-center rounded-full px-2 py-0.5 text-sm font-medium'
    )}
  >
    {value ? 'Yes' : 'No'}
  </span>
);
