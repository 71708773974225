import React from 'react';

import { classNames } from '@/lib/classNames';

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  /**
   * Defines the validity of the select
   */
  valid?: boolean;
  /**
   * Defines the border-radius of the select
   */
  rounded?: boolean;
}

const styles = Object.freeze({
  base: 'block w-full pl-3 mt-1 text-sm border-gray-300 rounded-md',
  active: 'focus:outline-none focus:ring-primary focus:border-primary',
  select: 'form-select leading-5',
  multiple: 'form-multiselect',
  disabled: 'cursor-not-allowed opacity-50 bg-gray-300',
  valid: 'border-green-600 focus:border-green-400 focus:shadow-outline-green',
  invalid: 'border-red-600 focus:border-red-400 focus:shadow-outline-red',
  rounded:
    'py-3 pl-4 pr-4 text-secondary placeholder-blue-800 font-bold rounded-3xl'
});

// Important: Consider using <Listbox> from Headless UI instead
export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  function Select(props, ref) {
    const {
      valid,
      children,
      className,
      multiple,
      disabled,
      rounded,
      ...other
    } = props;

    function hasValidation(valid: boolean | undefined) {
      return valid !== undefined;
    }

    function validationStyle(valid: boolean | undefined): string {
      if (hasValidation(valid)) {
        return valid ? styles.valid : styles.invalid;
      }
      return '';
    }

    const cls = classNames(
      styles.base,
      rounded && styles.rounded,
      // don't apply activeStyle if has valid or disabled
      !hasValidation(valid) && !disabled && styles.active,
      // don't apply disabledStyle if has valid
      !hasValidation(valid) && disabled && styles.disabled,
      validationStyle(valid),
      !multiple && styles.select,
      multiple && styles.multiple,
      className
    );

    return (
      <select
        className={cls}
        ref={ref}
        disabled={disabled}
        multiple={!!multiple}
        {...other}
      >
        {children}
      </select>
    );
  }
);

export default Select;
