import { IconType } from 'react-icons/lib';

export interface BadgeTableCellContentRendererProps {
  value: string;
  Icon: IconType;
}

export const BadgeTableCellContentRenderer = (
  props: BadgeTableCellContentRendererProps
): JSX.Element => {
  const { value, Icon } = props;

  return (
    <div className="flex flex-nowrap items-center">
      <div className="flex flex-nowrap items-center px-6 py-4">
        <div className="grow-0 rounded-full bg-gray-500 p-3">
          <Icon className="h-5 w-5 text-white" />
        </div>
        <div className="flex max-w-sm flex-col flex-nowrap items-start p-4">
          <div className="text-md max-w-full grow text-ellipsis font-bold text-gray-800">
            {value}
          </div>
        </div>
      </div>
    </div>
  );
};
