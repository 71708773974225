import { classNames } from '@/lib/classNames';

export type TextProps = React.PropsWithChildren<{
  size: 'xl' | 'lg' | 'md' | 'sm';
  className?: string;
  color?: 'orange' | 'blue' | 'white' | 'gray' | 'gray2' | 'primary';
}>;

const styles = {
  color: {
    orange: 'text-primary',
    primary: 'text-primary',
    blue: 'text-gray-650',
    white: 'text-white',
    gray: 'text-altGray',
    gray2: 'text-gray-600'
  },
  base: 'font-medium',
  xl: 'text-base lg:text-xl leading-normal md:leading-relaxed',
  lg: 'text-base lg:text-lg leading-normal',
  md: 'text-base leading-normal',
  sm: 'text-sm leading-snug'
};

export const Text = ({
  size,
  className,
  children,
  color = 'blue'
}: TextProps) => (
  <p
    className={classNames(
      styles.base,
      styles.color[color],
      styles[size],
      className
    )}
  >
    {children}
  </p>
);
