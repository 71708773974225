import React from 'react';
import LoadingSpinner from '@/components/util/LoadingSpinner';

type TableNoRecordsProps = {
  loading: boolean;
  description: string;
  action: string;
  onClick: () => void;
};
export default function TableNoRecords({
  loading,
  description,
  action,
  onClick
}: TableNoRecordsProps) {
  return (
    <>
      <div className="my-10 text-center">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <p>{description}</p>
            <p>
              <a href="#" className="font-bold underline" onClick={onClick}>
                {' '}
                {action}{' '}
              </a>
            </p>
          </>
        )}
      </div>
    </>
  );
}
