import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Column } from 'react-table';
import { classNames } from '@/lib/classNames';
import { DragDropTable } from '@/components/basic';
import { KeyOrderType, ListItemId } from '@/components/basic/DragDropTable';
import TableNoRecords from '@/components/partial/TableView/TableNoRecords';

export interface ReorderableDndTableProps<T extends ListItemId> {
  weightScaleFactor?: number;
  className?: string;
  reorderable: boolean;
  listItemsKeys: KeyOrderType[];
  setListItemsKeys: React.Dispatch<React.SetStateAction<KeyOrderType[]>>;
  updateFunction?: ((element: T, newWeight: number) => Promise<void>) | null;
  tableColumns: Column<T>[];
  tableData: T[] | undefined;
  tableId: string;
  emptyAction: string;
  emptyDescription: string;
  emptyActionCta: () => void;
}

export const ReorderableDndTable = <T extends ListItemId>(
  props: ReorderableDndTableProps<T>
) => {
  const queryClient = useQueryClient();
  const {
    weightScaleFactor = 10,
    className,
    reorderable,
    listItemsKeys,
    setListItemsKeys,
    updateFunction,
    tableColumns,
    tableData,
    tableId,
    emptyActionCta,
    emptyDescription,
    emptyAction
  } = props;
  const [areChangesBeingUploaded, setAreChangesBeingUploaded] =
    useState<boolean>(false);
  const loading = typeof tableData === 'undefined';
  const onOrderChange = (newOrder: KeyOrderType[]) => {
    const changesPromises: Promise<unknown>[] = [];

    newOrder.forEach((id, position) => {
      const element = tableData?.find((res) => res.id === id);
      if (
        !element ||
        typeof element.weight !== 'number' ||
        Number.isNaN(element.weight)
      ) {
        return;
      }
      const newWeight = (position + 1) * weightScaleFactor;
      if (element.weight !== newWeight) {
        if (updateFunction) {
          changesPromises.push(updateFunction(element, newWeight));
        } else {
          throw new Error(`Provide updateFunction or getUpdateEndpoint`);
        }
      }
    });

    setListItemsKeys(newOrder);
    setAreChangesBeingUploaded(true);
    Promise.all(changesPromises).finally(() => {
      setAreChangesBeingUploaded(false);
      // TODO -- this will invalidate all resource queries -- blunt hammer could be improved
      queryClient.invalidateQueries(['organizations.resourcesAll']);
    });
  };

  if (loading || tableData.length === 0) {
    return (
      <TableNoRecords
        loading={loading}
        onClick={emptyActionCta}
        description={emptyDescription}
        action={emptyAction}
      />
    );
  }

  return (
    <div className={classNames('flex flex-col', className)}>
      <div className="flex flex-auto flex-col overflow-y-auto">
        <DragDropTable
          listItemsKeys={listItemsKeys}
          onOrderChange={onOrderChange}
          disableDragging={!reorderable}
          id={tableId}
          columns={tableColumns}
          data={tableData}
        />
      </div>
    </div>
  );
};
