import { TypographyStylesProvider } from '@mantine/core';
import sanitizeHtml from 'sanitize-html';

type Props = { value: string; className?: string };

export function TextEditorContent({ value, className = '' }: Props) {
  return (
    <TypographyStylesProvider className={className}>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(value, {
            allowedClasses: { span: ['bg-yellow-400'] }
          })
        }}
      />
    </TypographyStylesProvider>
  );
}

export default TextEditorContent;
