import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useAuthenticated } from '@/lib/api/appUser';
import { getIntercomHash } from '@/lib/utils';
import { getFromStorage, setToStorage } from '@/hooks/useInstallTelemetry';
import { usePreviousState } from '@/hooks/usePreviousState';

declare global {
  interface Window {
    Intercom(action: 'update', options: object): void;
    Intercom(action: 'shutdown'): void;
    Intercom(action: 'boot', options: object): void;
    Intercom(action: 'hide', options?: object): void;
    Intercom(action: 'show', options?: object): void;
    Intercom(action: 'onHide', options?: object): void;
  }
}

const config = {
  // Intercom app ID
  intercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  // Populate with user name and email
  populateUserInfo: true,
  // Reset conversation history on signout (better privacy on shared computers)
  resetOnSignOut: true
};

export function Chat() {
  if (!config.intercomAppId) {
    console.warn('Intercom is disabled because app ID is not specified');
    return null;
  }

  return (
    <>
      <IntercomScript />
      <IntercomUpdater />
    </>
  );
}

function IntercomScript() {
  return (
    <Script
      id="intercom-widget"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === 'function') {
              ic('reattach_activator');
              ic('update', w.intercomSettings);
            } else {
              var d = document;
              var i = function () {
                i.c(arguments);
              };
              i.q = [];
              i.c = function (args) {
                i.q.push(args);
              };
              w.Intercom = i;
              var l = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = "https://widget.intercom.io/widget/${config.intercomAppId}";
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
              };
              if (document.readyState === 'complete') {
                l();
              } else if (w.attachEvent) {
                w.attachEvent('onload', l);
              } else {
                w.addEventListener('load', l, false);
              }
            }
          })();

          window.Intercom('boot', {
            api_base: 'https://api-iam.intercom.io',
            app_id: "${config.intercomAppId}",
            hide_default_launcher: true
          });
        `
      }}
    />
  );
}

function IntercomUpdater() {
  const router = useRouter();
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();

  const [currentIntercomHash, setCurrentIntercomHash] = useState('');

  // Update Intercom when route changes
  useEffect(() => {
    // Ping Intercom with new data (timestamp) so that it picks up the current page URL
    // See https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
    function handleRouteChange() {
      window.Intercom('update', {
        last_request_at: parseInt((new Date().getTime() / 1000).toString(), 10)
      });
    }

    // Call above handler function on route or hash change
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      // Remove handler if this component unmounts
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // we sync storage every time that organization slug changes
  useEffect(() => {
    if (!authenticatedUser?.organization?.slug) {
      setCurrentIntercomHash('');
      return;
    }
    const keyForOrganization =
      'intercomHash-' + authenticatedUser.organization.slug;
    const intercomHash = getFromStorage(keyForOrganization);
    if (intercomHash) {
      console.log(
        'Retrieved from storage...',
        keyForOrganization,
        intercomHash
      );
      setCurrentIntercomHash(intercomHash);
      return;
    } else {
      console.log('Calculating hash with network...');
      getIntercomHash(authenticatedUser.organization.slug).then((hash) => {
        setToStorage({ key: keyForOrganization, value: hash.hash });
        setCurrentIntercomHash(hash.hash);
      });
    }
  }, [authenticatedUser?.organization?.slug]);

  useEffect(() => {
    if (
      currentIntercomHash &&
      config.populateUserInfo &&
      authenticatedUser.user &&
      authenticatedUser.organization
    ) {
      window.Intercom('update', {
        user_id: authenticatedUser.user.id,
        user_hash: currentIntercomHash,
        email: authenticatedUser.user.email,
        name: authenticatedUser.user.name,
        phone: authenticatedUser.user.phone,
        avatar: authenticatedUser.user.profile_photo_url,
        // Everyone is a user and not a lead, the only two valid options per Intercom
        // Tactic role will need to be made available in a separate attribute
        // once we figure out how to send an array.
        role: 'user',
        company: {
          id: authenticatedUser.organization.id,
          name: authenticatedUser.organization.name,
          slug: authenticatedUser.organization.slug
        }
      });
    }
  }, [authenticatedUser.user, currentIntercomHash]);

  // Reset Intercom session when user signs out
  const previousUser = usePreviousState(authenticatedUser.user);
  useEffect(() => {
    // We know the user just signed out if we had a `auth.user` and now we don't
    const didSignOut = previousUser && !authenticatedUser.user;
    if (config.resetOnSignOut && didSignOut) {
      // Shut down Intercom session
      window.Intercom('shutdown');
      // Start a new session
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: config.intercomAppId
      });
    }
  }, [previousUser, authenticatedUser.user]);

  return null;
}

export default Chat;
