import React, { ReactNode } from 'react';
import { TableCellProps } from 'react-table';

export type TableCellWrapperProps = {
  children: ReactNode;
  isDragOccurring: boolean;
  tdProps: TableCellProps;
};

export type TableCellSnapshot = {
  width: number;
  height: number;
};

export class TableCellWrapper extends React.Component<TableCellWrapperProps> {
  ref?: React.RefObject<HTMLTableDataCellElement>;

  constructor(props: TableCellWrapperProps) {
    super(props);
    this.ref = React.createRef();
  }

  getSnapshotBeforeUpdate(
    prevProps: TableCellWrapperProps
  ): TableCellSnapshot | null {
    const element = this.ref?.current;
    if (!element) {
      return null;
    }

    const isDragStarting: boolean =
      this.props.isDragOccurring && !prevProps.isDragOccurring;

    if (!isDragStarting) {
      return null;
    }

    const { width, height } = element.getBoundingClientRect();

    const snapshot: TableCellSnapshot = {
      width,
      height
    };
    return snapshot;
  }

  componentDidUpdate(
    prevProps: TableCellWrapperProps,
    prevState: any,
    snapshot?: TableCellSnapshot
  ) {
    const element = this.ref?.current;

    if (!element) {
      return;
    }

    if (snapshot) {
      if (Number(element.style.width) === snapshot.width) {
        return;
      }
      element.style.width = `${snapshot.width}px`;
      element.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.props.isDragOccurring) {
      return;
    }

    // inline styles not applied
    if (element.style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    element.style.removeProperty('height');
    element.style.removeProperty('width');
  }

  render() {
    return (
      <td {...this.props.tdProps} ref={this.ref}>
        {this.props.children}
      </td>
    );
  }
}
