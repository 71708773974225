import React, { forwardRef, useMemo } from 'react';
import { IconType } from 'react-icons/lib';
import { classNames } from '@/lib/classNames';

export interface BaseButtonProps {
  /**
   * Tag to render. Defaults to 'button'
   */
  tag?: 'button' | 'a';
  /**
   * Defines if the button is disabled
   */
  disabled?: boolean;
  /**
   * The size of the button
   */
  size?: 'lg' | 'md';
  /**
   * Icon to show inside the button, left aligned
   */
  iconLeft?: IconType;
  /**
   * Icon to show inside the button, right aligned
   */
  iconRight?: IconType;
  /**
   * Button Variant
   */
  variant?: Variant;
  /**
   * Theme (color)
   */
  theme?: Theme;
}

type HtmlButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
type HtmlAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type ButtonProps = React.PropsWithChildren<
  BaseButtonProps & HtmlButtonProps & HtmlAnchorProps
>;

export enum Foundation {
  Normal = 'normal',
  NoShadow = 'noShadow'
}
export enum Padding {
  Normal = 'normal',
  Stretch = 'stretch',
  None = 'none'
}
export enum Alignment {
  Centered = 'centered',
  Left = 'left'
}
export enum Size {
  Large = 'lg',
  Medium = 'md'
}
export enum Sizes {
  Normal = 'normal',
  LighterFont = 'lighterFont',
  Small = 'small',
  SquaredNormal = 'squaredNormal'
}
export enum Theme {
  Base = 'base',
  Primary = 'primary',
  Blue = 'blue',
  White = 'white',
  Red = 'red'
}
export enum State {
  Enabled = 'enabled',
  Disabled = 'disabled'
}
export enum Variant {
  Primary = 'primary',
  PrimaryWhiteBorder = 'primaryWhiteBorder',
  PrimaryShort = 'primaryShort',
  PrimarySquared = 'primarySquared',
  TransparentBackground = 'transparentBackground',
  Secondary = 'secondary',
  Inverted = 'inverted',
  Borderless = 'borderless',
  BorderlessAlt = 'borderlessAlt',
  Anchor = 'anchor',
  AnchorFooter = 'anchorFooter'
}

export type FoundationType = string;
export type PaddingType = Record<Size, string>;
export type AlignmentType = string;
export type SizeType = string;
export type SizesType = Record<Size, SizeType>;
export type ThemeType = Record<Theme, string>;

export type VariantType = {
  foundation: FoundationType;
  sizes: SizesType;
  padding: PaddingType;
  alignment: AlignmentType;
  enabled: ThemeType;
  disabled: ThemeType;
};

const foundations: Record<Foundation, FoundationType> = Object.freeze({
  [Foundation.Normal]: 'shadow-sm focus:outline-none whitespace-nowrap',
  [Foundation.NoShadow]: 'shadow-none focus:outline-none whitespace-nowrap'
});
const sizes: Record<Sizes, SizesType> = Object.freeze({
  [Sizes.Normal]: {
    lg: 'text-lg leading-snug font-bold rounded-2xl',
    md: 'text-base leading-normal font-medium rounded-xl'
  },
  [Sizes.LighterFont]: {
    lg: 'text-lg leading-normal font-medium rounded-2xl',
    md: 'text-base leading-normal font-normal rounded-2xl'
  },
  [Sizes.Small]: {
    lg: 'text-sm leading-tight font-bold rounded-full',
    md: 'text-sm leading-tight font-bold rounded-full'
  },
  [Sizes.SquaredNormal]: {
    lg: 'text-lg leading-normal font-bold rounded',
    md: 'text-base leading-normal font-bold rounded'
  }
});
const paddings: Record<Padding, PaddingType> = Object.freeze({
  normal: {
    lg: 'py-4 px-8',
    md: 'py-3 px-4'
  },
  stretch: {
    lg: 'py-2 px-4',
    md: 'py-2 px-4'
  },
  none: {
    lg: 'p-0',
    md: 'p-0'
  }
});

const alignments: Record<Alignment, AlignmentType> = Object.freeze({
  centered: 'justify-center items-center',
  left: 'justify-start items-center'
});

const variants: Record<Variant, VariantType> = Object.freeze({
  [Variant.Primary]: {
    foundation: foundations[Foundation.Normal],
    sizes: sizes[Sizes.Normal],
    padding: paddings[Padding.Normal],
    alignment: alignments[Alignment.Centered],
    enabled: {
      [Theme.Base]: 'capitalize border-2',
      [Theme.Primary]:
        'bg-primary text-primary-text border-primary hover:bg-primary hover:border-primary',
      [Theme.Blue]:
        'bg-secondary-bg text-white border-blue-900 hover:bg-secondary',
      [Theme.White]: 'text-white',
      [Theme.Red]:
        'bg-red-500 text-white border-red-500 hover:bg-red-400 hover:border-red-400'
    },
    disabled: {
      [Theme.Base]: 'capitalize border-2 cursor-not-allowed',
      [Theme.Primary]: 'bg-disabled text-white border-disabled',
      [Theme.Blue]: 'bg-blue-200 text-white border-blue-200',
      [Theme.White]: 'bg-blue-200 text-white border-blue-200',
      [Theme.Red]: 'bg-blue-200 text-white border-blue-200'
    }
  },
  [Variant.PrimaryWhiteBorder]: {
    foundation: foundations[Foundation.Normal],
    sizes: sizes[Sizes.Normal],
    padding: paddings[Padding.Normal],
    alignment: alignments[Alignment.Centered],
    enabled: {
      [Theme.Base]: 'capitalize border-2',
      [Theme.Primary]:
        'bg-primary text-primary-text border-white hover:bg-primary',
      [Theme.Blue]:
        'bg-secondary-bg text-white border-white hover:bg-secondary',
      [Theme.White]: 'text-white',
      [Theme.Red]: 'bg-red-500 text-white border-white hover:bg-red-400'
    },
    disabled: {
      [Theme.Base]: 'capitalize border-2 cursor-not-allowed',
      [Theme.Primary]: 'bg-disabled text-white border-white',
      [Theme.Blue]: 'bg-blue-200 text-white border-white',
      [Theme.White]: 'text-white',
      [Theme.Red]: 'bg-blue-200 border-blue-200 text-white'
    }
  },
  [Variant.PrimaryShort]: {
    foundation: foundations[Foundation.NoShadow],
    sizes: sizes[Sizes.Small],
    padding: paddings[Padding.Stretch],
    alignment: alignments[Alignment.Centered],
    enabled: {
      [Theme.Base]: 'border',
      [Theme.Primary]:
        'bg-primary border-primary text-white hover:bg-primary-hover hover:border-primary-hover',
      [Theme.Blue]:
        'bg-secondary-bg border-secondary-bg text-white hover:bg-secondary hover:border-secondary',
      [Theme.White]:
        'bg-white border-highlight/20 text-tertiary hover:bg-gray-50',
      [Theme.Red]:
        'bg-red-500 text-white border-red-500 hover:bg-red-400 hover:border-red-400'
    },
    disabled: {
      [Theme.Base]: 'border cursor-not-allowed',
      [Theme.Primary]: 'bg-blue-200 border-blue-200 text-white',
      [Theme.Blue]: 'bg-blue-200 border-blue-200 text-white',
      [Theme.White]: 'opacity-50 bg-white border-highlight/20 text-tertiary',
      [Theme.Red]: 'bg-blue-200 border-blue-200 text-white'
    }
  },
  [Variant.PrimarySquared]: {
    foundation: foundations[Foundation.NoShadow],
    sizes: sizes[Sizes.SquaredNormal],
    padding: paddings[Padding.Normal],
    alignment: alignments[Alignment.Centered],
    enabled: {
      [Theme.Base]: 'border',
      [Theme.Primary]:
        'bg-primary text-white border-primary hover:bg-primary-hover hover:border-primary-hover',
      [Theme.Blue]:
        'bg-secondary text-white border-secondary hover:bg-secondary-hover hover:border-secondary-hover',
      [Theme.White]:
        'bg-white text-tertiary border-highlight/20 hover:bg-gray-50',
      [Theme.Red]:
        'bg-red-500 text-white border-red-500 hover:bg-red-400 hover:border-red-400'
    },
    disabled: {
      [Theme.Base]: 'border cursor-not-allowed',
      [Theme.Primary]: 'opacity-50 bg-disabled border-disabled text-white',
      [Theme.Blue]: 'opacity-50 bg-secondary border-secondary text-white',
      [Theme.White]: 'opacity-50 bg-white text-tertiary border-highlight/20',
      [Theme.Red]: 'opacity-50 bg-secondary border-secondary text-white'
    }
  },
  [Variant.TransparentBackground]: {
    foundation: foundations[Foundation.NoShadow],
    sizes: sizes[Sizes.LighterFont],
    padding: paddings[Padding.None],
    alignment: alignments[Alignment.Left],
    enabled: {
      [Theme.Base]: 'capitalize border-0',
      [Theme.Primary]: 'bg-transparent text-white',
      [Theme.Blue]: 'bg-transparent text-white',
      [Theme.White]: 'bg-transparent text-white',
      [Theme.Red]: 'bg-transparent text-white'
    },
    disabled: {
      [Theme.Base]: 'capitalize cursor-not-allowed',
      [Theme.Primary]: 'bg-transparent text-blue-200',
      [Theme.Blue]: 'bg-transparent text-blue-200',
      [Theme.White]: 'bg-transparent text-blue-200',
      [Theme.Red]: 'bg-transparent text-blue-200'
    }
  },
  [Variant.Secondary]: {
    foundation: foundations[Foundation.Normal],
    sizes: sizes[Sizes.Normal],
    padding: paddings[Padding.Normal],
    alignment: alignments[Alignment.Centered],
    enabled: {
      [Theme.Base]: 'capitalize border-2 bg-white',
      [Theme.Primary]:
        'text-primary border-primary hover:bg-primary-hover hover:border-primary hover:text-white',
      [Theme.Blue]:
        'text-secondary-bg border-blue-900 hover:bg-secondary-bg hover:border-blue-900 hover:text-white',
      [Theme.White]: 'text-white',
      [Theme.Red]:
        'text-red-500 border-red-500 hover:bg-red-400 hover:border-red-400 hover:text-white'
    },
    disabled: {
      [Theme.Base]: 'capitalize bg-white border-2 cursor-not-allowed',
      [Theme.Primary]: 'text-blue-200 border-blue-200',
      [Theme.Blue]: 'text-blue-200 border-blue-200',
      [Theme.White]: 'text-blue-200 border-blue-200',
      [Theme.Red]: 'text-blue-200 border-blue-200'
    }
  },
  [Variant.Inverted]: {
    foundation: foundations[Foundation.Normal],
    sizes: sizes[Sizes.Normal],
    padding: paddings[Padding.Normal],
    alignment: alignments[Alignment.Centered],
    enabled: {
      [Theme.Base]: 'capitalize bg-white border-2',
      [Theme.Primary]:
        'text-secondary border-blue-800 hover:text-primary hover:border-primary-hover',
      [Theme.Blue]:
        'text-primary border-primary hover:text-secondary hover:border-blue-900',
      [Theme.White]: 'text-white',
      [Theme.Red]:
        'text-red-500 border-red-500 hover:text-red-500 hover:border-red-400'
    },
    disabled: {
      [Theme.Base]: 'capitalize bg-white border-2 cursor-not-allowed',
      [Theme.Primary]: 'text-blue-200 border-blue-200',
      [Theme.Blue]: 'text-blue-200 border-blue-200',
      [Theme.White]: 'text-blue-200 border-blue-200',
      [Theme.Red]: 'text-blue-200 border-blue-200'
    }
  },
  [Variant.Borderless]: {
    foundation: foundations[Foundation.Normal],
    sizes: sizes[Sizes.Normal],
    padding: paddings[Padding.None],
    alignment: alignments[Alignment.Centered],
    enabled: {
      [Theme.Base]: 'uppercase border-0 shadow-none bg-transparent',
      [Theme.Primary]: 'text-primary hover:text-primary-hover',
      [Theme.Blue]: 'text-secondary hover:text-secondary-bg',
      [Theme.White]: 'text-white',
      [Theme.Red]: 'text-red-500 hover:text-red-600'
    },
    disabled: {
      [Theme.Base]:
        'uppercase border-0 shadow-none bg-transparent cursor-not-allowed',
      [Theme.Primary]: 'text-blue-200',
      [Theme.Blue]: 'text-blue-200',
      [Theme.White]: 'text-blue-200',
      [Theme.Red]: 'text-blue-200'
    }
  },
  [Variant.BorderlessAlt]: {
    foundation: foundations[Foundation.Normal],
    sizes: sizes[Sizes.Normal],
    padding: paddings[Padding.Normal],
    alignment: alignments[Alignment.Centered],
    enabled: {
      [Theme.Base]: 'capitalize border-0 shadow-none bg-transparent',
      [Theme.Primary]: 'text-primary hover:text-primary-hover',
      [Theme.Blue]: 'text-secondary hover:text-secondary-bg',
      [Theme.White]: 'text-white',
      [Theme.Red]: 'text-red-500 hover:text-red-600'
    },
    disabled: {
      [Theme.Base]:
        'capitalize border-0 shadow-none bg-transparent cursor-not-allowed',
      [Theme.Primary]: 'text-primary',
      [Theme.Blue]: 'text-blue-200',
      [Theme.White]: 'text-blue-200',
      [Theme.Red]: 'text-blue-200'
    }
  },
  [Variant.Anchor]: {
    foundation: foundations[Foundation.NoShadow],
    sizes: sizes[Sizes.Normal],
    padding: paddings[Padding.None],
    alignment: alignments[Alignment.Left],
    enabled: {
      [Theme.Base]:
        'capitalize border-0 shadow-none bg-transparent hover:underline cursor-pointer',
      [Theme.Primary]: 'text-primary hover:text-primary-hover',
      [Theme.Blue]: 'text-secondary hover:text-secondary-bg',
      [Theme.White]: 'text-white',
      [Theme.Red]: 'text-red-500 hover:text-red-600'
    },
    disabled: {
      [Theme.Base]:
        'capitalize border-0 shadow-none bg-transparent cursor-not-allowed',
      [Theme.Primary]: 'text-blue-200',
      [Theme.Blue]: 'text-blue-200',
      [Theme.White]: 'text-blue-200',
      [Theme.Red]: 'text-blue-200'
    }
  },
  [Variant.AnchorFooter]: {
    foundation: foundations[Foundation.NoShadow],
    sizes: sizes[Sizes.LighterFont],
    padding: paddings[Padding.None],
    alignment: alignments[Alignment.Left],
    enabled: {
      [Theme.Base]:
        'capitalize border-0 shadow-none bg-transparent hover:underline cursor-pointer',
      [Theme.Primary]: 'text-primary hover:text-primary-hover',
      [Theme.Blue]: 'text-secondary hover:text-secondary-bg',
      [Theme.White]: 'text-white',
      [Theme.Red]: 'text-red-500 hover:text-red-600'
    },
    disabled: {
      [Theme.Base]:
        'capitalize border-0 shadow-none bg-transparent cursor-not-allowed',
      [Theme.Primary]: 'text-blue-200',
      [Theme.Blue]: 'text-blue-200',
      [Theme.White]: 'text-blue-200',
      [Theme.Red]: 'text-blue-200'
    }
  }
});

export const NewButton = forwardRef(function NewButton(
  props: ButtonProps,
  ref
) {
  const {
    tag = 'button',
    type = 'button',
    disabled = false,
    size = 'lg',
    iconLeft = null,
    iconRight = null,
    variant = 'primary',
    theme = 'primary',
    children,
    className,
    ...rest
  } = props;

  const styles = useMemo(() => variants[variant], [variant]);
  const stateStyles = useMemo(
    () =>
      disabled
        ? classNames(styles.disabled.base, styles.disabled[theme])
        : classNames(styles.enabled.base, styles.enabled[theme]),
    [styles, theme, disabled]
  );

  return React.createElement(
    tag,
    {
      className: classNames(
        variant === Variant.Anchor ? 'inline-block' : 'flex',
        styles.foundation,
        styles.sizes[size],
        styles.alignment,
        styles.padding[size],
        stateStyles,
        className
      ),
      type,
      disabled,
      ref,
      ...rest
    },
    [
      iconLeft
        ? React.createElement(iconLeft, {
            key: '1',
            className: 'w-6 h-6 mr-3',
            'aria-hidden': true
          })
        : null,
      children,
      iconRight
        ? React.createElement(iconRight, {
            key: '2',
            className: 'w-6 h-6 ml-4',
            'aria-hidden': true
          })
        : null
    ]
  );
});
