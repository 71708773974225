import React, { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd';
import { Column, useTable } from 'react-table';
import { TableCellWrapper } from './TableCellWrapper';

export type KeyOrderType = string | number;

export interface ListItemId {
  id: KeyOrderType;
  weight?: number;
}

export interface DndTableProps<T extends ListItemId> {
  onOrderChange: (newOrder: KeyOrderType[]) => void;
  disableDragging: boolean;
  listItemsKeys: KeyOrderType[];
  id: KeyOrderType;
  columns: Column<T>[];
  data: T[];
}
export const DragDropTable = <T extends ListItemId>(
  props: DndTableProps<T>
) => {
  const { onOrderChange, disableDragging, listItemsKeys, id, columns, data } =
    props;
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>({ columns, data });

  const onBeforeDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    const orderDidChange =
      destination &&
      (destination.droppableId !== source.droppableId ||
        destination.index !== source.index);
    if (destination && orderDidChange) {
      const newState: KeyOrderType[] = Array.from(listItemsKeys);
      newState.splice(source.index, 1);
      newState.splice(destination.index, 0, draggableId);
      onOrderChange(newState);
    }
    setIsDragging(false);
  };

  return (
    <DragDropContext
      onBeforeDragStart={onBeforeDragStart}
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId={id.toString()}>
        {(provided) => (
          <div className="overflow-hidden rounded-md border bg-white shadow-sm">
            <table className="w-full" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  // eslint-disable-next-line react/jsx-key
                  <tr
                    className="border-b border-gray-300 bg-gray-50"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      // eslint-disable-next-line react/jsx-key
                      <th
                        className="py-3 text-left font-bold text-gray-800"
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Draggable
                      isDragDisabled={disableDragging}
                      draggableId={listItemsKeys[index].toString()}
                      key={listItemsKeys[index]}
                      index={index}
                    >
                      {(provided) => (
                        <tr
                          className="border-t border-b border-gray-300 bg-white last:border-b-0"
                          {...row.getRowProps()}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {row.cells.map((cell, index) => (
                            <TableCellWrapper
                              isDragOccurring={isDragging}
                              tdProps={cell.getCellProps()}
                              key={index}
                            >
                              {cell.render('Cell')}
                            </TableCellWrapper>
                          ))}
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </tbody>
            </table>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
