import { MdDelete, MdEdit } from 'react-icons/md';
import { Row } from 'react-table';
import { IResource } from '@gettactic/api';

export interface ResourceEditDeleteViewScheduleControlsTableCellContentRendererProps<
  T extends IResource
> {
  row: Row<T>;
  setEditModal: (item: T | null) => void;
  setDeleteModal?: (item: T[]) => void;
}

export const ResourceEditDeleteViewScheduleControlsTableCellContentRenderer = <
  T extends IResource
>(
  props: ResourceEditDeleteViewScheduleControlsTableCellContentRendererProps<T>
): JSX.Element => {
  const { row, setEditModal, setDeleteModal } = props;
  const resource = row.original;
  return (
    <div className="flex flex-nowrap items-center p-4">
      <button
        onClick={() => setEditModal(resource)}
        className="px-2 text-gray-600 hover:text-gray-800"
      >
        <MdEdit className="h-5 w-5" />
      </button>
      {setDeleteModal ? (
        <button
          onClick={() => setDeleteModal([resource])}
          className="px-2 text-gray-600 hover:text-gray-800"
        >
          <MdDelete className="h-5 w-5" />
        </button>
      ) : null}
    </div>
  );
};
