import base from './base';
import { themes } from './index';

export interface ITheme {
  [key: string]: any;
}
export interface IThemes {
  [key: string]: ITheme;
}
export interface IMappedTheme {
  [key: string]: string | null;
}

export const mapTheme = (variables: ITheme): IMappedTheme => {
  return {
    // ---------------------
    // Primary Colors
    // ---------------------
    '--color-primary': variables?.primary.DEFAULT || base.primary.DEFAULT,
    '--color-primary-text': variables?.primary.text || base.primary.text,
    '--color-primary-bg': variables?.primary.bg || base.primary.bg,
    '--color-primary-hover': variables?.primary.hover || base.primary.hover,
    '--color-primary-lighter':
      variables?.primary.lighter || base.primary.lighter,
    '--color-primary-darker': variables?.primary.darker || base.primary.darker,
    // ---------------------
    // Tertiary Colors
    // ---------------------
    '--color-tertiary': variables?.tertiary.DEFAULT || base.tertiary.DEFAULT,
    '--color-tertiary-text': variables?.tertiary.text || base.tertiary.text,
    '--color-tertiary-bg': variables?.tertiary.bg || base.tertiary.bg,
    '--color-tertiary-hover': variables?.tertiary.hover || base.tertiary.hover,
    // ---------------------
    // Secondary Colors
    // ---------------------
    '--color-secondary': variables?.secondary.DEFAULT || base.secondary.DEFAULT,
    '--color-secondary-bg': variables?.secondary.bg || base.secondary.bg,
    '--color-secondary-text': variables?.secondary.text || base.secondary.text,
    '--color-secondary-hover':
      variables?.secondary.hover || base.secondary.hover,
    // ---------------------
    // General Colors
    // ---------------------
    '--color-brand': variables?.brand || base.brand,
    '--color-highlight': variables?.highlight || base.highlight,
    '--color-disabled': base.disabled,
    '--color-event-past': base.event.past.DEFAULT,
    '--color-hyperlink': variables?.hyperlink || base.hyperlink,
    '--color-input': variables?.input || base.input,
    '--color-background': variables?.background || base.background,
    '--color-overlay': variables?.overlay || base.overlay,
    // ---------------------
    // Status Colors
    // ---------------------
    '--color-success': variables?.success?.DEFAULT || base.success.DEFAULT,
    '--color-success-text': variables?.success?.text || base.success.text,
    '--color-success-bg': variables?.success?.bg || base.success.bg,
    // '--color-warning': variables?.warning?.DEFAULT || base.warning.DEFAULT,
    '--color-error': variables?.error?.DEFAULT || base.error.DEFAULT,
    '--color-error-text': variables?.error?.text || base.error.text,
    '--color-error-bg': variables?.error?.bg || base.error.bg,

    // ---------------------
    // Map Colors
    // ---------------------

    '--map-available-color':
      variables?.map?.availableColor ?? base.map.availableColor,
    '--map-not-available-color':
      variables?.map?.notAvailableColor ?? base.map.notAvailableColor,
    '--map-spot-not-bookeable-unavailable-stroke':
      variables?.map?.spotNotBookeableUnavailableStroke ??
      base.map.spotNotBookeableUnavailableStroke,
    '--map-spot-not-bookeable-unavailable-fill':
      variables?.map?.spotNotBookeableUnavailableFill ??
      base.map.spotNotBookeableUnavailableFill,
    '--map-spot-not-bookeable-stroke':
      variables?.map?.spotNotBookeableStroke ?? base.map.spotNotBookeableStroke,
    '--map-spot-not-bookeable-fill':
      variables?.map?.spotNotBookeableFill ?? base.map.spotNotBookeableFill,
    '--map-spot-requires-approval-stroke':
      variables?.map?.spotRequiresApprovalStroke ??
      base.map.spotRequiresApprovalStroke,
    '--map-spot-requires-approval-fill':
      variables?.map?.spotRequiresApprovalFill ??
      base.map.spotRequiresApprovalFill,
    '--map-spot-bookeable-with-schedules-stroke':
      variables?.map?.spotBookeableWithSchedulesStroke ??
      base.map.spotBookeableWithSchedulesStroke,
    '--map-spot-fully-available-stroke':
      variables?.map?.spotFullyAvailableStroke ??
      base.map.spotFullyAvailableStroke,
    '--map-spot-not-assigned-fill':
      variables?.map?.spotNotAssignedFill ?? base.map.spotNotAssignedFill
  };
};

export const applyTheme = (theme: string): void => {
  const themeObject: IMappedTheme = mapTheme(themes[theme]);
  if (!themeObject || typeof window === 'undefined') return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    if (property === 'name') {
      return;
    }

    root.style.setProperty(property, themeObject[property]);
  });
};

export const extend = (extending: ITheme, newTheme: ITheme): ITheme => {
  return { ...extending, ...newTheme };
};
