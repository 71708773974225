import { IResource } from '@gettactic/api';
import { IconType } from 'react-icons/lib';
import { Row } from 'react-table';

export interface ResourceBadgeTableCellContentRendererProps {
  row: Row<IResource>;
  name: string;
  areas?: IResource[];
  IconSingleSlot: IconType;
  IconMultipleSlots: IconType;
}

export const ResourceBadgeTableCellContentRenderer = (
  props: ResourceBadgeTableCellContentRendererProps
) => {
  const { row, name, areas, IconSingleSlot, IconMultipleSlots } = props;

  const { total_slots, parent_id } = row.original;
  const parentName = areas?.find((area) => area.id === parent_id)?.name || null;
  const iconClassName = 'w-5 h-5 text-white';

  return (
    <div className="flex flex-nowrap items-center">
      <div className="flex flex-nowrap items-center px-6 py-4">
        <div className="grow-0 rounded-full bg-gray-500 p-3">
          {total_slots > 1 ? (
            <IconMultipleSlots className={iconClassName} />
          ) : (
            <IconSingleSlot className={iconClassName} />
          )}
        </div>
        <div className="flex max-w-sm flex-col flex-nowrap items-start p-4">
          <div className="text-md max-w-full grow text-ellipsis font-bold text-gray-800">
            {name}
          </div>
          {parentName ? (
            <div className="text-sm">
              Belongs:{' '}
              <span className="font-bold text-gray-900 ">{parentName}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
