import { InformationCircleIcon } from '@heroicons/react/solid';
import React, { useMemo } from 'react';
import {
  Tooltip,
  TooltipReference,
  TooltipState,
  useTooltipState
} from 'reakit/Tooltip';
import { classNames } from '@/lib/classNames';

export type TacticTooltipProps = React.PropsWithChildren<{
  className?: string;
  size?: 'md' | 'lg';
  reference?: JSX.Element;
  stateProps?: Partial<TooltipState>;
}>;

const DefaultTooltipReference = ({
  size
}: Pick<TacticTooltipProps, 'size'>) => {
  const tooltipIconClassNames = useMemo(
    () => (size === 'lg' ? 'w-6 h-6' : 'w-5 h-5'),
    [size]
  );
  return (
    <InformationCircleIcon
      className={classNames(
        'ml-1 text-primary hover:text-opacity-75',
        tooltipIconClassNames
      )}
      aria-hidden="true"
    />
  );
};

export const TacticTooltip = ({
  className,
  size,
  reference,
  stateProps = {},
  children
}: TacticTooltipProps) => {
  const tooltip = useTooltipState({ animated: 500, ...stateProps });

  return (
    <div
      className={classNames('flex items-center justify-start', className || '')}
    >
      <TooltipReference as="span" {...tooltip}>
        {reference ?? <DefaultTooltipReference size={size} />}
      </TooltipReference>
      <Tooltip {...tooltip} className="z-30 max-w-md">
        <div className="tooltip-box rounded-lg border border-gray-200 bg-white p-2 opacity-0 shadow-lg transition duration-500 ease-in-out">
          {children}
        </div>
      </Tooltip>
    </div>
  );
};

export default TacticTooltip;
