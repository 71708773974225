import { IResource } from '@gettactic/api';
import { Row } from 'react-table';
import ResourceRestrictionInfo from '@/components/partial/Cards/Resources/ResourceRestrictionInfo';

export interface RestrictedLabelsTableCellContentRendererProps {
  row: Row<IResource>;
}

export const RestrictedLabelsTableCellContentRenderer = ({
  row
}: RestrictedLabelsTableCellContentRendererProps) => {
  const resource = row.original;
  return (
    <div className="px-4">
      <ResourceRestrictionInfo resource={resource} restrictedTagsOnly />
    </div>
  );
};
