import { classNames } from '@/lib/classNames';
import React from 'react';
import { HiExclamationCircle as Exclamation } from 'react-icons/hi';

export interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  /**
   * Defines whether there is a valid/invalid state
   */
  valid?: boolean;
}

const styles = Object.freeze({
  base: 'shadow-sm block w-full text-base border-gray-300 rounded-md',
  active: 'focus:ring-primary focus:border-primary',
  disabled: 'cursor-not-allowed opacity-50 bg-gray-300',
  valid: 'border-green-600 focus:border-green-400 focus:shadow-outline-green',
  invalid:
    'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500',
  radio:
    'text-primary form-radio focus:border-primary focus:outline-none focus:shadow-outline-primary cursor-pointer',
  checkbox:
    'rounded text-primary form-checkbox focus:border-primary focus:outline-none focus:shadow-outline-primary'
});

function hasValidation(valid: boolean | undefined) {
  return valid !== undefined;
}

function validationStyle(valid: boolean | undefined): string {
  if (hasValidation(valid)) {
    return valid ? styles.valid : styles.invalid;
  }
  return '';
}

function typeStyle(type: string): string {
  switch (type) {
    case 'radio':
      return styles.radio;
    case 'checkbox':
      return styles.checkbox;
    default:
      return styles.base;
  }
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  function Input(props, ref) {
    const { valid, disabled, className, type = 'text', ...other } = props;
    const inputClassNames = classNames(
      typeStyle(type),
      // don't apply activeStyle if it has valid or disabled
      !hasValidation(valid) && !disabled && styles.active,
      // don't apply disabledStyle if it has valid
      !hasValidation(valid) && disabled && styles.disabled,
      validationStyle(valid),
      className
    );

    return (
      <>
        <div
          className={classNames(
            type === 'checkbox' || type === 'radio'
              ? 'relative inline-flex cursor-pointer'
              : 'relative mt-1 rounded-md'
          )}
        >
          <input
            className={inputClassNames}
            disabled={disabled}
            type={type}
            ref={ref}
            {...other}
          />
          {hasValidation(valid) && !valid && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <Exclamation className="h-5 w-5 text-red-500" />
            </div>
          )}
        </div>
      </>
    );
  }
);

export default Input;
