import React from 'react';
import { MdDelete, MdEdit, MdSync } from 'react-icons/md';
import { Row } from 'react-table';
import { ListItemId } from '@/components/basic/DragDropTable';

export interface EditDeleteControlsTableCellContentRenderer<
  T extends ListItemId
> {
  row: Row<T>;
  setEditModal?: (item: T | null) => void;
  setDeleteModal?: (item: T | null) => void;
  setRefreshModal?: (item: T | null) => void;
}

export const EditDeleteControlsTableCellContentRenderer = <
  T extends ListItemId
>(
  props: EditDeleteControlsTableCellContentRenderer<T>
): JSX.Element => {
  const { row, setEditModal, setDeleteModal, setRefreshModal } = props;
  return (
    <div className="flex flex-nowrap items-center p-2">
      {setEditModal ? (
        <button
          onClick={(ev) => {
            ev.preventDefault();
            setEditModal(row.original);
          }}
          className="px-2 text-gray-600 hover:text-gray-800"
        >
          <MdEdit className="h-5 w-5" />
        </button>
      ) : null}
      {setRefreshModal ? (
        <button
          onClick={(ev) => {
            ev.preventDefault();
            setRefreshModal(row.original);
          }}
          className="px-2 text-gray-600 hover:text-gray-800"
        >
          <MdSync className="h-5 w-5" />
        </button>
      ) : null}
      {setDeleteModal ? (
        <button
          onClick={(ev) => {
            ev.preventDefault();
            setDeleteModal(row.original);
          }}
          className="px-2 text-gray-600 hover:text-gray-800"
        >
          <MdDelete className="h-5 w-5" />
        </button>
      ) : null}
    </div>
  );
};
