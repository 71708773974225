import React, { ForwardedRef } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuthenticated } from '@/lib/api/appUser';
import { useI18n } from '@/hooks/useI18n';

export const DatePicker = React.forwardRef(
  (props: ReactDatePickerProps, ref: ForwardedRef<ReactDatePicker>) => {
    const {
      userContext: { authenticatedUser }
    } = useAuthenticated();
    const { templates } = useI18n(authenticatedUser);
    const dateFormat = props.dateFormat ?? templates.displayDate;
    return (
      <ReactDatePicker
        ref={ref}
        dateFormat={dateFormat}
        calendarStartDay={1}
        className="mt-1 block w-full rounded-3xl border-gray-300 px-4 py-3 text-sm font-bold text-secondary placeholder-blue-800 focus:border-primary focus:outline-none focus:ring-primary"
        {...props}
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
      />
    );
  }
);
export default DatePicker;
