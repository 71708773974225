import { api } from '@/lib/api/api';
import { getDeviceTZ } from '@/lib/utils';
import { AuthenticatedUser, Office } from '@gettactic/api';
import Cookies from 'js-cookie';
import { createContext, useContext, useEffect, useReducer } from 'react';

type Action =
  | { type: 'updateCurrentOffice'; payload: string }
  | { type: 'updateDeviceTz'; payload: string }
  | { type: 'updateAuthUser'; payload: AuthenticatedUser };

type Dispatch = (action: Action) => void;

type State = {
  authenticatedUser: AuthenticatedUser;
  deviceTz: string;
  currentOffice: Office;
};

export interface AppAuthenticatedContextProps {
  userContext: State;
  setUserContext: Dispatch;
}

const AppAuthenticatedContext = createContext<
  AppAuthenticatedContextProps | undefined
>(undefined);

function userContextReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'updateAuthUser': {
      return {
        ...state,
        authenticatedUser: action.payload
      };
    }
    case 'updateCurrentOffice': {
      const officeMatch = (
        state.authenticatedUser?.offices?.offices || []
      ).find((x) => x.id === action.payload);
      if (!officeMatch) {
        throw new Error('Office not found');
      }
      Cookies.set('__selected__office', action.payload);
      return {
        ...state,
        currentOffice: officeMatch
      };
    }
    case 'updateDeviceTz': {
      return {
        ...state,
        deviceTz: action.payload
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

export default function AppAuthenticatedProvider({
  authenticatedUser,
  children
}: {
  authenticatedUser: AuthenticatedUser;
  children: React.ReactNode;
}) {
  // TODO: Might need to revisit this. Not sure if this will cause too many unnecessary rerenders
  useEffect(() => {
    action({ type: 'updateAuthUser', payload: authenticatedUser });
  }, [authenticatedUser]);

  // Once we know the authenticated user, need to update the api-client
  // since it now will need the organization slug to append the organization header
  if (authenticatedUser) {
    api.updateUser(authenticatedUser);
  }

  const cookieOffice = Cookies.get('__selected__office');
  const selectedCookieOffice = authenticatedUser?.offices?.offices.find(
    (id) => id.id === cookieOffice
  );

  // Initialize officeId with the user's defaultOfficeId. If no default set, use the first in the list.
  // If no default & no offices in the list, this user should be in the setup wizard or in a visitor-only page
  const defaultOrFirstOffice =
    selectedCookieOffice ||
    (authenticatedUser?.offices?.officeId
      ? authenticatedUser?.offices?.offices?.find(
          (x) => x.id === authenticatedUser?.offices?.officeId
        )
      : authenticatedUser?.offices
        ? authenticatedUser?.offices.office // User Default
        : authenticatedUser?.offices?.offices[0]); // First office in the list

  if (!defaultOrFirstOffice) {
    throw new Error('No office found');
  }

  const [state, action] = useReducer(userContextReducer, {
    authenticatedUser: authenticatedUser,
    deviceTz:
      getDeviceTZ() ??
      defaultOrFirstOffice.time_zone ??
      authenticatedUser?.user?.time_zone ??
      'UTC',
    currentOffice: defaultOrFirstOffice
  });

  const value = {
    userContext: state,
    setUserContext: action
  };

  return (
    <AppAuthenticatedContext.Provider value={value}>
      {children}
    </AppAuthenticatedContext.Provider>
  );
}

/**
 * Provides global authenticated user context & global modals
 */
export function useAuthenticated() {
  const context = useContext(AppAuthenticatedContext);
  if (context === undefined) {
    throw new Error(
      'useAuthenticated must be used within a AppAuthenticatedContext.Provider'
    );
  }
  return context;
}

export function useAuthenticatedUser() {
  const { userContext } = useAuthenticated();
  return userContext.authenticatedUser;
}

export function useAuthenticatedCurrentOffice() {
  const { userContext } = useAuthenticated();
  return userContext.currentOffice;
}
