import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogFooterProps,
  DialogHeader
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import { useWindowSize } from '@gettactic/helpers/src/hooks/useWindowSize';
import React from 'react';

export interface ModalProps extends React.PropsWithChildren {
  opened: boolean;
  onClose: () => void;
  onOpenChange?: (isOpen: boolean) => void;
  title?: string | JSX.Element;
  size?: string;
  closeOnOverlayClick?: boolean;
  isModal?: boolean;
}

export interface ModalComponent extends React.FC<ModalProps> {
  Body: React.FC<React.PropsWithChildren>;
  Footer: React.FC<DialogFooterProps>;
}

export const Modal: ModalComponent = ({
  children,
  opened,
  onClose,
  title,
  size,
  closeOnOverlayClick = true,
  isModal
}) => {
  const browserWindowSize = useWindowSize();
  const browserHeight = browserWindowSize.height;

  return (
    <Dialog open={opened} onOpenChange={onClose} modal={isModal}>
      <DialogContent
        className={`${size ? size : 'max-w-[30rem]'}`}
        onOpenAutoFocus={(e) => e.preventDefault()}
        overlayProps={{
          onClick: closeOnOverlayClick ? onClose : undefined
        }}
        onInteractOutside={(e) => e.preventDefault()}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <DialogHeader>
          {typeof title === 'string' ? (
            <span className="font-bold text-2xl text-secondary">{title}</span>
          ) : (
            title || null
          )}
        </DialogHeader>
        <div className={`py-4 ${size ? size : 'max-w-[30rem]'}`}>
          <div
            style={{
              maxHeight: browserHeight ? `${browserHeight - 200}px` : '32rem'
            }}
            className={cn(
              'pr-3 pl-1',
              browserHeight && browserHeight < 700 ? 'overflow-y-auto' : ''
            )}
          >
            {children}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const ModalBody: ModalComponent['Body'] = ({ children }) => {
  return <>{children}</>;
};

export const ModalFooter: ModalComponent['Footer'] = (props) => {
  return (
    <DialogFooter {...props} className={cn('mt-4 mb-1', props.className)} />
  );
};

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
export default Modal;
