import colors from 'tailwindcss/colors';
import { getDarkerColor, getLighterColor } from './base';

const PRIMARY_COLOR = '#46829D';
const PRIMARY_CONTRAST_COLOR = '#FFFFFF';

const SECONDARY_COLOR = '#082F49';
const SECONDARY_CONTRAST_COLOR = '#FFFFFF';

const TERTIARY_COLOR = '#020617';
const TERTIARY_CONTRAST_COLOR = '#FFFFFF';

const BRAND_ACCENT = colors.teal[600];
const HIGHLIGHT = '186 230 253'; //rgb format

const base = {
  brand: BRAND_ACCENT,
  highlight: HIGHLIGHT,
  primary: {
    bg: PRIMARY_COLOR,
    DEFAULT: PRIMARY_COLOR,
    text: PRIMARY_CONTRAST_COLOR,
    hover: getLighterColor(PRIMARY_COLOR, 0.8),
    lighter: getLighterColor(PRIMARY_COLOR, 0.05),
    darker: getDarkerColor(PRIMARY_COLOR, 0.1)
  },
  secondary: {
    DEFAULT: SECONDARY_COLOR,
    bg: SECONDARY_COLOR,
    text: SECONDARY_CONTRAST_COLOR,
    hover: getLighterColor(SECONDARY_COLOR, 0.8)
  },
  tertiary: {
    DEFAULT: TERTIARY_COLOR,
    text: TERTIARY_CONTRAST_COLOR,
    bg: TERTIARY_COLOR,
    hover: getLighterColor(TERTIARY_COLOR, 0.8)
  }
};

export default base;
