import React from 'react';
import clsx from 'clsx';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Removes default styles (if true) so you can override with your own background styles
   */
  colorful?: boolean;
  /**
   * Overflow variable
   */
  overflow?: 'hidden' | 'visible';
}

const styles = Object.freeze({
  base: 'min-w-0 rounded-lg shadow',
  default: 'bg-white'
});

/**
 * Primary UI component for displaying content.
 * We use the words "Cards" and "Panels" interchangeably.
 */
export const CardContainer = React.forwardRef<HTMLDivElement, CardProps>(
  function Card(props, ref) {
    const {
      className,
      children,
      colorful = false,
      overflow = 'hidden',
      ...other
    } = props;

    return (
      <div
        className={clsx(
          styles.base,
          !colorful && styles.default,
          `overflow-${overflow}`,
          className
        )}
        ref={ref}
        {...other}
      >
        {children}
      </div>
    );
  }
);

export default CardContainer;
