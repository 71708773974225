import { classNames } from '@/lib/classNames';
import React, { ChangeEvent, useImperativeHandle, useRef } from 'react';
import Select, { Props } from 'react-select';

function ResponsiveSelect<
  T extends { label: string | JSX.Element; value: string }
>(props: Props<T>, ref: React.Ref<{ focus: () => void }>) {
  const { className, options, value, onChange, ...rest } = props;

  const desktopSelectRef = useRef<Select>(null);
  const nativeSelectRef = useRef<HTMLSelectElement>(null);

  const desktopProps = {
    options,
    value,
    onChange,
    ...rest
  };

  const nativeProps = {
    onChange: (event: ChangeEvent<HTMLSelectElement>) => {
      const option = options?.find(
        // @ts-expect-error
        (option) => option.value === event.currentTarget.value
      );
      // @ts-expect-error
      return onChange?.(option, undefined);
    },
    // @ts-expect-error
    value: value?.value,
    ref: nativeSelectRef
  };

  useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        if (desktopSelectRef?.current !== null) {
          //@ts-expect-error
          desktopSelectRef.current.focus();
        }
        if (nativeSelectRef?.current !== null) {
          nativeSelectRef.current.focus();
        }
      }
    }),
    [desktopSelectRef, nativeSelectRef]
  );
  return (
    <>
      <Select
        //@ts-expect-error
        ref={desktopSelectRef}
        className={classNames(
          className,
          'text-trueGray-800 hidden rounded-full shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:block sm:text-black'
        )}
        classNamePrefix="react-select"
        {...desktopProps}
      />
      <select
        {...nativeProps}
        className="text-trueGray-800 block w-full rounded-md border border-slate-200 pl-4 pr-8 focus:border-primary focus:outline-none focus:ring-primary sm:hidden"
      >
        {options?.map((option, index: number) =>
          'value' in option ? (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ) : null
        )}
      </select>
    </>
  );
}

export default React.forwardRef(ResponsiveSelect);
