import base from './base';
import elemis from './elemis';
import smartsweets from './smartsweets';
import exness from './exness';
import hpe from './hpe';
import redbull from './redbull';
import type { IThemes } from './utils';

/**
 * The default theme to load
 */
export const DEFAULT_THEME = 'base';
export const RED_BULL_THEME = 'redbull';

export const themes: IThemes = {
  base,
  redbull,
  hpe,
  elemis,
  exness,
  smartsweets
};
