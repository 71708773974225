import React from 'react';
import { classNames } from '@/lib/classNames';

export interface ResponsiveLabelProps
  extends React.HTMLProps<HTMLLabelElement> {
  text: string;
  classes?: string;
  filled: boolean;
}

export const ResponsiveLabel = (props: ResponsiveLabelProps) => {
  const { className, text, classes, children, filled, ...rest } = props;
  return (
    <div className={classNames(className, 'responsive-label relative')}>
      <label
        className={classNames(
          classes,
          filled && 'filled',
          'text-trueGray-500 pointer-events-none absolute block rounded-lg bg-white font-normal transition-transform duration-200 ease-out sm:static sm:p-0 sm:font-bold sm:text-gray-700'
        )}
        {...rest}
      >
        {text}
      </label>
      {children}
    </div>
  );
};

export default ResponsiveLabel;
