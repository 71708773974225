// TODO this is only for the testing purpose, need to remove it later

// TODO this is only for the testing purpose, need to remove it later

import { getDarkerColor, getLighterColor } from './base';

const PRIMARY_COLOR = '#01A982';
const PRIMARY_CONTRAST_COLOR = '#FFFFFF';

const SECONDARY_COLOR = '#082F49';
const SECONDARY_CONTRAST_COLOR = '#FFFFFF';

const TERTIARY_COLOR = '#020617';
const TERTIARY_CONTRAST_COLOR = '#FFFFFF';

const BRAND_ACCENT = '#01A982';
const HIGHLIGHT = '0 185 139'; //rgb format

const hpe = {
  brand: BRAND_ACCENT,
  highlight: HIGHLIGHT,
  primary: {
    bg: PRIMARY_COLOR, // Primary background color
    DEFAULT: PRIMARY_COLOR, // Default primary color,
    text: PRIMARY_CONTRAST_COLOR, // Primary contrast color,
    hover: getLighterColor(PRIMARY_COLOR, 0.8),
    lighter: getLighterColor(PRIMARY_COLOR, 0.05),
    darker: getDarkerColor(PRIMARY_COLOR, 0.1)
  },
  secondary: {
    DEFAULT: SECONDARY_COLOR,
    bg: SECONDARY_COLOR,
    text: SECONDARY_CONTRAST_COLOR,
    hover: getLighterColor(SECONDARY_COLOR, 0.8)
  },
  tertiary: {
    DEFAULT: TERTIARY_COLOR,
    bg: TERTIARY_COLOR,
    text: TERTIARY_CONTRAST_COLOR,
    hover: getLighterColor(TERTIARY_COLOR, 0.8)
  },
  map: {
    availableColor: '#10B981',
    notAvailableColor: 'rgba(169, 169, 169, 1)',
    spotNotBookeableUnavailableStroke: 'rgba(153, 95, 95, 0.46)',
    spotNotBookeableUnavailableFill: 'rgba(232, 236, 239, 1)',
    spotNotBookeableStroke: 'rgba(255, 255, 255, 1)',
    spotNotBookeableFill: '#FBAF57',
    spotRequiresApprovalStroke: 'rgba(255, 255, 255, 1)',
    spotRequiresApprovalFill: '#E8ECEF',
    spotBookeableWithSchedulesStroke: 'rgba(255, 255, 255, 1)',
    spotFullyAvailableStroke: 'rgba(255, 255, 255, 1)',
    spotNotAssignedFill: 'rgba(232, 236, 239, 1)'
  }
};

export default hpe;
