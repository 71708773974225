import {
  AuthenticatedUser,
  DATE_FORMAT_STYLE_DMY,
  DATE_FORMAT_STYLE_MDY,
  DATE_FORMAT_STYLE_YMD,
  DateFormatStyle,
  ResGetUser
} from '@gettactic/api';

export type LocalizationDateTimeDictionary = Readonly<{
  displayDate: string;
  displayDateShort: string;
  displayDateWordy: string;
  displayMonthDayYearWordy: string;
  displayMonthDayWordy: string;
  displayDateTimestamp: string;
}>;

type LocaleDictionary = {
  [dateFormat in DateFormatStyle]: LocalizationDateTimeDictionary;
};

const dictionary: LocaleDictionary = {
  [DATE_FORMAT_STYLE_DMY]: {
    displayDate: 'dd/MM/yyyy',
    displayDateShort: 'dd/MM/yy',
    displayDateWordy: 'eeee, LLLL d',
    displayMonthDayYearWordy: 'do MMM, yyyy',
    displayMonthDayWordy: 'do LLL',
    displayDateTimestamp: 'dd/MM/yyyy HH:mm:ss'
  },
  [DATE_FORMAT_STYLE_MDY]: {
    displayDate: 'MM/dd/yyyy',
    displayDateShort: 'MM/dd/yy',
    displayDateWordy: 'eeee, LLLL do',
    displayMonthDayYearWordy: 'MMM do, yyyy',
    displayMonthDayWordy: 'LLL do',
    displayDateTimestamp: 'MM/dd/yyyy HH:mm:ss'
  },
  [DATE_FORMAT_STYLE_YMD]: {
    displayDate: 'yyyy-MM-dd',
    displayDateShort: 'yy-MM-dd',
    displayDateWordy: 'LLLL eeee do',
    displayMonthDayYearWordy: 'yyyy do, MMM',
    displayMonthDayWordy: 'do LLL',
    displayDateTimestamp: 'yyyy-MM-dd HH:mm:ss'
  }
};

export function useI18n(user: AuthenticatedUser | ResGetUser) {
  const dateFormat =
    'user' in user
      ? user?.user?.organization_user?.date_format ?? DATE_FORMAT_STYLE_MDY
      : user?.date_format ?? DATE_FORMAT_STYLE_MDY;
  const templates = dictionary[dateFormat];
  return { templates };
}
