import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { Alert } from '@/components/basic';
import DuplicateEmailMessage from '@/components/basic/FormMessages/DuplicateEmailMessage';

type FormError = {
  type: 'duplicate' | 'generic' | 'serverStrength';
  message?: string;
};
type FormErrorDictValue =
  | FormError
  | FieldError
  | FieldError[]
  | null
  | undefined
  | { [fieldName: string]: FormErrorsDict }
  | FieldErrorsImpl<{ [x: string]: any }>;

export type FormErrorsDict = {
  [fieldName: string]: FormErrorDictValue;
};

type Props = {
  errors:
    | FormErrorsDict
    | Partial<FieldErrorsImpl<{ [x: string]: any }>>
    | Merge<FieldError, FieldErrorsImpl<any>>
    | null
    | undefined;
  fieldName: string;
};

function getAlertTitle(error: FormError | FieldError, fieldName: string) {
  if (error.message) {
    return error.message;
  }
  switch (error.type) {
    case 'duplicate':
      return `${fieldName} already exists, already exists.`;
    case 'serverStrength':
      return `"${fieldName} does not meet requirements."`;
    //case 'generic':
    default:
      return 'It seems something went wrong. Try again or reach out to support@gettactic.com.';
  }
}

export default function FormErrorMessage({ errors, fieldName }: Props) {
  const error = errors ? errors[fieldName] : null;
  if (!error) {
    return null;
  }
  if (Array.isArray(error)) {
    return (
      <>
        {error.map((x) => (
          <FormErrorMessage
            key={fieldName}
            errors={{ [fieldName]: x }}
            fieldName={fieldName}
          />
        ))}
      </>
    );
  }
  if (error.type === 'duplicate' && fieldName === 'email') {
    return <DuplicateEmailMessage />;
  }
  //@ts-expect-error Remove warning expecting [idx: string]: FormDict
  const message = getAlertTitle(error, fieldName);
  return (
    <Alert
      variant="error"
      canDismiss={false}
      alertTitle={message}
      alertMessage=""
      className="mt-2 px-4 py-2"
    />
  );
}
