import { cn } from '@gettactic/helpers';
import { useDebouncedEffect } from '@gettactic/helpers/src/hooks/useDebouncedEffect';
import React, { useCallback, useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';

export interface SearchProps extends React.HTMLAttributes<HTMLInputElement> {
  query: string;
  setQuery:
    | React.Dispatch<React.SetStateAction<string>>
    | ((query: string) => void);
  debounceTimeout?: number;
  name?: string;
  inputId?: string;
  placeholder?: string;
  preventAutoLowerCase?: boolean;
  fullWidth?: boolean;
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchProps>(
  function Search(props, ref) {
    const {
      query,
      setQuery,
      debounceTimeout = 400,
      placeholder = 'Search',
      name = 'search',
      inputId = 'search',
      preventAutoLowerCase = false,
      fullWidth = false,
      className,
      ...other
    } = props;
    // we create a local queryInput to sync state with input
    // so we can call the real one only after a debounced effect
    const [queryInput, setQueryInput] = useState(query);
    useDebouncedEffect(
      () => setQuery(queryInput),
      [queryInput],
      debounceTimeout
    );
    const handleQueryInput: React.ChangeEventHandler<HTMLInputElement> =
      useCallback((e) => {
        const queryString = e.currentTarget.value;
        setQueryInput(
          preventAutoLowerCase ? queryString : queryString.toLowerCase()
        );
      }, []);

    const resetQueryField = useCallback(() => {
      setQuery('');
      setQueryInput('');
    }, []);

    // Keep queryInput synced with parent query
    // to reflect external changes
    useEffect(() => {
      setQueryInput(query);
    }, [query]);

    const hasFilter = query.length > 0;

    return (
      <div className="flex items-center">
        <label htmlFor={inputId} className="sr-only">
          Search
        </label>
        <div className={cn('relative', fullWidth && 'w-full')}>
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              className="h-5 w-5 shrink-0 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="search"
            className={cn(
              'search-input-no-reset block rounded-md border border-gray-300 bg-white ' +
                'px-10 py-1.5 placeholder-gray-500 focus:border-primary focus:text-gray-800 ' +
                'focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-primary',
              className
            )}
            placeholder={placeholder}
            name={name}
            id={inputId}
            value={queryInput}
            onChange={handleQueryInput}
            {...other}
            ref={ref}
            onKeyDown={(event) => {
              if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
              }
            }}
          />
          {hasFilter && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <HiX
                className="h-5 w-5 shrink-0 cursor-pointer text-gray-400 hover:text-gray-700"
                onClick={resetQueryField}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default SearchInput;
