// TODO this is only for the testing purpose, need to remove it later

import { getDarkerColor, getLighterColor } from './base';

const PRIMARY_COLOR = '#3369E5';
const PRIMARY_CONTRAST_COLOR = '#FFFFFF';

const SECONDARY_COLOR = '#051629';
const SECONDARY_CONTRAST_COLOR = '#FFFFFF';

const TERTIARY_COLOR = '#020617';
const TERTIARY_CONTRAST_COLOR = '#FFFFFF';

const BRAND_ACCENT = '#307BFB';
const HIGHLIGHT = '63 105 229'; //rgb format

const redbull = {
  brand: BRAND_ACCENT,
  highlight: HIGHLIGHT,
  primary: {
    bg: PRIMARY_COLOR, // Primary background color
    DEFAULT: PRIMARY_COLOR, // Default primary color,
    text: PRIMARY_CONTRAST_COLOR, // Primary contrast color,
    hover: getLighterColor(PRIMARY_COLOR, 0.8),
    lighter: getLighterColor(PRIMARY_COLOR, 0.2),
    darker: getDarkerColor(PRIMARY_COLOR, 0.1)
  },
  secondary: {
    DEFAULT: SECONDARY_COLOR,
    bg: SECONDARY_COLOR,
    text: SECONDARY_CONTRAST_COLOR,
    hover: getLighterColor(SECONDARY_COLOR, 0.8)
  },
  tertiary: {
    DEFAULT: TERTIARY_COLOR,
    bg: TERTIARY_COLOR,
    text: TERTIARY_CONTRAST_COLOR,
    hover: getLighterColor(TERTIARY_COLOR, 0.8)
  }
};

export default redbull;
